.custom-dropdown {
  overscroll-behavior: contain;
}
.base-input-select {
  /** antd.selectのスタイル調整 */
  .ant-select-selector {
    height: 100% !important;
    border: none !important;
    font-family: 'Noto Sans JP', 'sans-serif' !important;
    align-items: center;

    .ant-select-selection-placeholder {
      @apply font-medium text-disabled;
    }

    .ant-select-selection-item {
      @apply font-medium text-navy;
    }

    /** スマホ */
    @media not all and (min-width: 640px) {
      font-size: 14px !important;
    }

    /** タブレット */
    @media (min-width: 640px) {
      font-size: 18px !important;
    }

    /** PC */
    @media (min-width: 1024px) {
      font-size: 18px !important;
    }
    &:focus {
      border: none !important;
    }
    input {
      height: 100% !important;
    }
  }
}
