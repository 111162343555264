@import './mixin';

.bar-area {
  .swiper-button-prev,
  .swiper-button-next {
    top: calc(var(--top) / 2) !important;
  }
}
.apros_carousel--swiper {
  .swiper-slide img {
    display: block;
    object-fit: cover;
  }

  .swiper-button-prev,
  .swiper-button-next {
    --height: 30px;
    height: var(--height);
    width: 30px;
    --halfHeight: calc(var(--height) / 2);
    --top: calc(100% + var(--halfHeight));
    top: calc(var(--top) / 2 - var(--height) / 2);
  }
  /* 前へ次への矢印カスタマイズ */
  .swiper-button-prev:after,
  .swiper-button-next:after {
    background-repeat: no-repeat;
    background-size: contain;
    content: '';
    margin: auto;
    height: 30px;
    width: 30px;
  }
  /* 前への矢印カスタマイズ */
  .swiper-button-prev:after {
    background-image: url('../../images/svg/icon_back.svg');
  }
  /* 次への矢印カスタマイズ */
  .swiper-button-next:after {
    background-image: url('../../images/svg/icon_next.svg');
  }

  @include pc {
    .swiper-button-prev,
    .swiper-rtl .swiper-button-next {
      left: 0px;
    }
    .swiper-button-next,
    .swiper-rtl .swiper-button-prev {
      right: 0px;
    }
  }
  @include tab {
    .swiper-button-prev,
    .swiper-rtl .swiper-button-next {
      left: 0px;
    }
    .swiper-button-next,
    .swiper-rtl .swiper-button-prev {
      right: 0px;
    }
  }
}
