.apros--modal_calendar {
  .ant-picker-calendar {
    background: #f9f9fa;
    padding: 0 0.5rem;
  }
  .ant-picker-panel {
    background: #f9f9fa;
    border-top: 1px solid #d0d0d9;
    .ant-picker-body {
      margin: 1.5rem 0 2rem 0;
      padding: 0;
    }
  }

  thead tr {
    th {
      color: #4c657c !important;
      font-size: 16px;
      font-family: 'Noto Sans JP';
      font-weight: 500 !important;
    }
    th:first-child {
      color: #e71f19 !important;
    }
    th:last-child {
      color: #23629b !important;
    }
  }
}
