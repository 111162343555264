.baseCheckbox-input {
  margin: 0;
  width: 0;
  opacity: 0;
}

.baseCheckbox-input:checked + .baseCheckbox-dummy {
  background: #002445;
}

.baseCheckbox-input:checked + .baseCheckbox-dummy::before {
  content: '';
  display: block;
  position: absolute;
  top: 45%;
  left: 60%;
  width: 55%;
  height: 4px;
  border-radius: 2px;
  transform: translate(-6px, 5px) rotateZ(-135deg);
  transform-origin: 2px 2px;
  background: #ffffff;
}

.baseCheckbox-input:checked + .baseCheckbox-dummy::after {
  content: '';
  display: block;
  position: absolute;
  top: 45%;
  left: 60%;
  width: 76%;
  height: 4px;
  border-radius: 2px;
  transform: translate(-6px, 5px) rotateZ(-45deg);
  transform-origin: 2px 2px;
  background: #ffffff;
}

.baseCheckbox-dummy {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  width: 26px;
  height: 26px;
  border: solid 2px transparent;
  background: #ffffff;
  border-radius: 4px;
  border: 1px solid #002445;
}
