@import url(https://fonts.googleapis.com/earlyaccess/notosansjp.css);
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  @apply  bg-background-main font-sans font-medium;
}

#root {
  height: 100vh;
  height: 100dvh;
  position: relative;
  overflow: auto;
}
body {
  overflow: hidden !important;
}

/** スマホ */
@media not all and (min-width: 640px) {
  #root {
    position: relative;
    /* min-height: 100vh; */
    /* padding-bottom: 122px; */
     /*←footerの高さ*/
    box-sizing: border-box;
  }
  .text-xs {
    font-size: 12px;
  }
  .text-sm {
    font-size: 14px;
  }
  .text-base {
    font-size: 16px;
  }
  .text-lg {
    font-size: 18px;
  }
  .text-xl {
    font-size: 22px;
  }
}

/** タブレット */
@media (min-width: 640px) {
  #root {
    position: relative;
    min-height: 100vh;
    /* padding-bottom: 111px;  */
    /*←footerの高さ*/
    box-sizing: border-box;
  }
  .text-xs {
    font-size: 16px;
  }
  .text-sm {
    font-size: 18px;
  }
  .text-base {
    font-size: 24px;
  }
  .text-lg {
    font-size: 30px;
  }
  .text-xl {
    font-size: 40px;
  }
}

/** PC */
@media (min-width: 1024px) {
  #root {
    position: relative;
    min-height: 100vh;
    /* padding-bottom: 107px;  */
    /*←footerの高さ*/
    box-sizing: border-box;
  }
  .text-xs {
    font-size: 16px;
  }
  .text-sm {
    font-size: 18px;
  }
  .text-base {
    font-size: 24px;
  }
  .text-lg {
    font-size: 32px;
  }
  .text-xl {
    font-size: 46px;
  }
}
