.notice-area {
  @media screen and (max-width: 800px) {
    flex-direction: column !important;
  }
}
.ant-tabs-nav {
  width: 100% !important;
  margin-bottom: 0 !important;
}

.ant-tabs-tab {
  display: block; // centers text inside tabs
  flex: 1;
  text-align: center;
  justify-content: space-around;
}

.ant-tabs-nav > div:nth-of-type(1) {
  display: unset !important;
  width: 100% !important;
}

.ant-tabs .ant-tabs-tab + .ant-tabs-tab {
  margin: 0;
}

.ant-tabs-ink-bar {
  background: #ca4539 !important;
  height: 4px !important;
}

@media not all and (min-width: 640px) {
  .ant-tabs-tab-btn {
    font-size: 18px;
  }
}

@media (min-width: 640px) {
  .ant-tabs-tab-btn {
    font-size: 24px;
  }
}

.ant-tabs-tab-btn {
  color: #002445 !important;
  font-weight: bold;
}

$border: 1px;
$height: 10px;
$width: 10px;

%clip-triangle {
  clip-path: polygon(50% 0%, 0% 86.6%, 100% 86.6%);
}

.triangle {
  @extend %clip-triangle;
  background-color: #d0d0d9;
  height: $height;
  position: absolute;
  width: $width;

  &::before {
    @extend %clip-triangle;
    background-color: #f9f9fa;
    bottom: calc((100% - 40%) / 2);
    content: '';
    height: $height - ($border * 0.6);
    left: 50%;
    position: absolute;
    transform: translate(-50%, calc((100% - 15.4%) / 2));
    width: $width - ($border * 0.6);
  }
}
