@import './mixin';

$md-radio-color: #002445;

$md-radio-size-pc: 26px;
$md-radio-size-sp: 22px;

$md-radio-checked-size-pc: 14px;
$md-radio-checked-size-sp: 12px;

.md-radio {
  &.md-radio-inline {
    display: inline-block;
  }

  input[type='radio'] {
    display: none;
    &:checked + label:before {
      border-color: $md-radio-color;
      background-color: #ffffff;
      border: 2px solid $md-radio-color;
    }
    &:checked + label:after {
      transform: scale(1);
    }
  }

  label {
    display: inline-block;
    @include pc {
      min-height: $md-radio-size-pc;
      padding: 0 ($md-radio-size-pc + 10px);
    }
    @include tab {
      min-height: $md-radio-size-pc;
      padding: 0 ($md-radio-size-pc + 10px);
    }
    @include sp {
      min-height: $md-radio-size-sp;
      padding: 0 ($md-radio-size-sp + 10px);
    }
    position: relative;
    margin-bottom: 0;
    cursor: pointer;
    vertical-align: bottom;
    &:before,
    &:after {
      position: absolute;
      content: '';
      border-radius: 50%;
      transition-property: transform, border-color;
    }
    &:before {
      left: 0;
      top: 0;
      @include pc {
        width: $md-radio-size-pc;
        height: $md-radio-size-pc;
      }
      @include tab {
        width: $md-radio-size-pc;
        height: $md-radio-size-pc;
      }
      @include sp {
        width: $md-radio-size-sp;
        height: $md-radio-size-sp;
      }
      background-color: #ffffff;
      border: 1px solid $md-radio-color;
    }
    &:after {
      @include pc {
        top: calc($md-radio-size-pc / 2 - $md-radio-checked-size-pc / 2);
        left: calc($md-radio-size-pc / 2 - $md-radio-checked-size-pc / 2);
        width: $md-radio-checked-size-pc;
        height: $md-radio-checked-size-pc;
      }
      @include tab {
        top: calc($md-radio-size-pc / 2 - $md-radio-checked-size-pc / 2);
        left: calc($md-radio-size-pc / 2 - $md-radio-checked-size-pc / 2);
        width: $md-radio-checked-size-pc;
        height: $md-radio-checked-size-pc;
      }
      @include sp {
        top: calc($md-radio-size-sp / 2 - $md-radio-checked-size-sp / 2);
        left: calc($md-radio-size-sp / 2 - $md-radio-checked-size-sp / 2);
        width: $md-radio-checked-size-sp;
        height: $md-radio-checked-size-sp;
      }
      transform: scale(0);
      background: $md-radio-color;
    }
  }
}
