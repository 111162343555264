.header_row {
  &--hamburger_menu {
    &--inner {
      .ant-collapse-header {
        display: none !important;
      }
      .ant-collapse-content-box {
        padding: 0 !important;
      }
    }

    &--range {
      @media screen and (min-width: 640px) {
        height: 1024px;
        width: 512px;
        right: 0;
        font-size: 18px;
      }
      @media not all and (min-width: 640px) {
        height: 100%;
        width: 100%;
        left: 0;
      }
    }

    &--base_font {
      @media screen and (min-width: 640px) {
        font-size: 18px;
      }
      @media not all and (min-width: 640px) {
        font-size: 14px;
      }
    }

    &--bold_font_18_16 {
      @media screen and (min-width: 640px) {
        font-size: 18px;
      }
      @media not all and (min-width: 640px) {
        font-size: 16px;
      }
    }

    &--bold_font_24_16 {
      @media screen and (min-width: 640px) {
        font-size: 24px;
      }
      @media not all and (min-width: 640px) {
        font-size: 16px;
      }
    }

    &--font_16_12 {
      @media screen and (min-width: 640px) {
        font-size: 16px;
      }
      @media not all and (min-width: 640px) {
        font-size: 12px;
      }
    }

    &--font_18_12 {
      @media screen and (min-width: 640px) {
        font-size: 18px;
      }
      @media not all and (min-width: 640px) {
        font-size: 12px;
      }
    }

    .menu_icon {
      position: relative; /*ボタン内側の基点となるためrelativeを指定*/
      background: #002445;
      cursor: pointer;
      width: 38px;
      height: 36px;
      border: 1px solid #d0d0d9;
      border-radius: 2px;
      span {
        display: inline-block;
        transition: all 0.4s; /*アニメーションの設定*/
        position: absolute;
        left: 5px;
        height: 4px;
        border-radius: 1px;
        background: #ffffff;
        width: 26px;
        pointer-events: none;

        &:nth-of-type(1) {
          top: 5px;
        }
        &:nth-of-type(2) {
          top: 15px;
        }
        &:nth-of-type(3) {
          top: 25px;
        }
      }
      &.active {
        opacity: 0;
      }
      &.active span:nth-of-type(1) {
        top: 15px;
        transform: translateY(0px) rotate(-45deg);
      }
      &.active span:nth-of-type(2) {
        opacity: 0; /*真ん中の線は透過*/
      }
      &.active span:nth-of-type(3) {
        top: 15px;
        transform: translateY(0px) rotate(45deg);
      }
    }
  }

  &--user_info_menu {
    &--hover {
      @media screen and (max-width: 1319px) {
        right: 0;
      }

      @media screen and (min-width: 1320px) {
        left: 0;
      }
    }
  }
}
