.apros_acoordion {
  font-size: 18px;
  background-color: #00244510;
  margin-top: 20px;
  padding-left: 40px;
  border-bottom-width: 0px;
  height: 48px;
}
.apros_acoordion_body {
  padding-left: 40px;
  padding-right: 40px;
}
