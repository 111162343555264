// スクロールバーのスタイル変更
.scrollBar {
  &::-webkit-scrollbar {
    width: 6px;
    height: 10x;
  }
  overflow-x: hidden;
  &::-webkit-scrollbar-thumb {
    background: #d0d0d9;
    border-radius: 3px;
  }
  &::-webkit-scrollbar-track {
    border-radius: 3px;
  }

  -moz-scrollbar-color: #d0d0d9;
  scrollbar-color: #d0d0d9;
}

input,
label {
  -webkit-touch-callout: none;
  -webkit-text-size-adjust: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  // -webkit-user-select: none;
}

// input type=numberの上下を消す
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none !important;
  margin: 0 !important;
  -moz-appearance: textfield !important;
}

.kiyaku-table {
  td {
    border-width: 1px;
    border-color: black;
    padding: 2px 20px 2px 10px;
  }
}
