@mixin pc {
  @media (min-width: 1024px) {
    @content;
  }
}

@mixin tab {
  @media (min-width: 640px) {
    @content;
  }
}

@mixin sp {
  @media not all and (min-width: 640px) {
    @content;
  }
}
